import Projects from '@/layout/Projects'

export default [{
    path: '/floorplanner',
    component: Projects,
    children: [
        {
            path: 'projects',
            component: () => import('@components/projects/ProjectList.vue')
        },
        {
            path: 'project/:id?',
            name: 'project',
            component: () => import('@components/projects/ProjectSingle.vue')
        },
        {
            path: 'project/:pid/floor/:id?',
            name: 'floor',
            component: () => import('@components/projects/FloorSingle.vue')
        },
        {
            path: 'editor/:id',
            name: 'floorplaneditor',
            component: () => import('@components/projects/FloorplanEditor.vue')
        },
        
    ]
}]

