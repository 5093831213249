import ENV from '../../env.json'

export default function () {
  return {
    // Public url
    publicUrl: process.env.BASE_URL,
    apiUrl: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal : ENV.apiUrl,
    fileServerUrl: process.env.NODE_ENV !== 'production' ? ENV.fileServerUrlLocal : ENV.fileServerUrl,
    googleMapsKey: process.env.GOOGLE_MAPS_KEY,
  }
}
