import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import globals from './globals'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import ENV from '../../env.json'
import './style.css'
import { useUserStore } from './stores/user'

const app = createApp(App)

app.use(VueSweetalert2);
app.config.productionTip = false
app.mixin({
    data: globals
})

const instance = axios.create({
    // baseURL: ENV.apiUrl,
    baseURL: process.env.NODE_ENV !== 'production' ? ENV.apiUrlLocal : ENV.apiUrl,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
  });

  const instancePublic = axios.create({
    // baseURL: ENV.apiUrl,
    baseURL: process.env.NODE_ENV !== 'production' ? ENV.fileServerUrlLocal : ENV.fileServerUrl,
    timeout: 10000,
    withCredentials: true,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
  });

//   instance.interceptors.request.use((request) => {
//     const token = localStorage.getItem('access_token')
//     request.headers.Authorization = 'Bearer ' + token

//     // const userStore = useUserStore()
//     // request.headers.Authorization = userStore.token
//     // console.log('Adding token to header', userStore.token)
        

//   })

//   Vue.prototype.$http.defaults.timeout = 10000

//   instance.interceptors.request.use(
//     config => {
//       const token = localStorage.getItem('access_token')
//       if (token) {
//         config.headers.common.Authorization = 'Bearer ' + token
//       }
//       return config
//     },
//     error => {
//       return Promise.reject(error)
//     }
//   )
  
  instance.interceptors.response.use(
    response => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response)
      } else {
        return Promise.reject(response)
      }
    },
    error => {
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            //  do something
            break
          case 401:
            alert('session expired')
            router.replace({
              path: '/auth/login',
              query: { redirect: router.currentRoute.fullPath }
            })
            break
          case 403:
            router.replace({
              path: '/auth/login',
              query: { redirect: router.currentRoute.fullPath }
            })
            break
          case 404:
            alert('page not exist')
            break
          case 502:
            setTimeout(() => {
              router.replace({
                path: '/auth/login',
                query: {
                  redirect: router.currentRoute.fullPath
                }
              })
            }, 1000)
        }
        return Promise.reject(error.response)
      }
    }
  )

app.provide('$http', instance)
app.provide('$httpPublic', instancePublic)

app.use(router)

app.mount('#app')


