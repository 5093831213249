<template>

<div>
    <header>
       
    <div class="wrapper">
    
      <nav>
        <div class="hidden xl:block py-5 px-6 bg-white border-b">
          
              <a v-show="currentRouteName.hasOwnProperty('id')" class="absolute flex mr-10 items-center text-sm text-gray-500 hover:text-gray-600" href="javascript:history.go(-1)">
                  
                <svg xmlns="http://www.w3.org/2000/svg" class="text-gray-100a w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M16 15v-1a4 4 0 00-4-4H8m0 0l3 3m-3-3l3-3m9 14V5a2 2 0 00-2-2H6a2 2 0 00-2 2v16l4-2 4 2 4-2 4 2z" />
                </svg>
                
                <span>Inapoi</span>
              
              </a>

            <div class="flex items-center1 max-w-7xl px-4 sm:px-6 lg:px-8">
              
              <div class="flex items-center1 w-1/7 ml-4 px-4 sm:px-6 lg:px-8"> &nbsp; </div>
              <ul class="flex items-center">
                <li>
                  <RouterLink class="flex mr-10 items-center text-sm text-gray-500 hover:text-gray-600" to="/floorplanner/projects">
                  
                  <svg class="text-gray-100 w-5 h-5 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                    </svg>
                   
                    <span>Proiecte</span>
                  
                  </RouterLink>

           
                </li>
                
              </ul>
              
            </div>
          </div>
      </nav>

      
    </div>
  </header>

      <main>
        <router-view />
      </main>
</div>


</template>

<script>

export default {
  name: 'app-layout-1',
  
  computed: {
    currentRouteName() {
        return this.$route.params;
    }
  },
  methods: {
    
  }
}
</script>
