import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import Vue from 'vue'
// import Router from 'vue-router'

// Vue.use(Router)
import NotFound from '@components/NotFound'
import inventory from './inventory'
import projects from './projects'
import auth from './auth'

const ROUTES = [
  // Default route
  { path: '', redirect: '/home' },
  {
    path: '/home',
      component: () => import('@components/common/Dashboard.vue')
  },
]
  .concat(auth)
  .concat(inventory)
  .concat(projects)
  // .concat(auth)
  // .concat(user)
  // .concat(admin)

// 404 Not Found page
ROUTES.push({
  path: '/:pathMatch(.*)*',
  component: NotFound
})

const router = createRouter({
  base: '/',
  // history: process.env.IS_ELECTRON ? 'hash' : 'history',
  history: createWebHashHistory(),
  routes: ROUTES
})

router.afterEach(() => {
  // On small screens collapse sidenav
  // if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
  //   setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  // }

  // Scroll to top of the page
  // globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})


export default router
