import Inventory from '@/layout/Inventory'

export default [{
    path: '/inventory',
    component: Inventory,
    children: [
        {
            path: 'list',
            component: () => import('@components/inventory/Products.vue')
        },
        {
            path: 'product/:id?',
            name: 'product',
            component: () => import('@components/inventory/SingleProduct.vue')
        },
        {
            path: 'categories',
            name: 'categories',
            component: () => import('@components/inventory/Categories.vue')
        },
        {
            path: 'categories/single/:id?',
            name: 'category',
            component: () => import('@components/inventory/SingleCategory.vue')
        },
        {
            path: 'tags',
            name: 'tags',
            component: () => import('@components/inventory/Tags.vue')
        },
    ]
}]

